<template>
  <v-card flat class="rounded-xl">
    <v-card-title> </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        onSubmit="return false;"
        v-if="!isLoggingIn"
        v-model="isFormValid"
      >
        <v-row>
          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-text-field
              :class="['mt-0']"
              :label="'Token'"
              :rules="[rules.required]"
              :value="JSON.stringify(this.token)"
              disabled
              outlined
              v-if="me.isSuperuser"
            ></v-text-field>
          </v-col>

          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-text-field
              :hint="
                teamleaderMe.id
                  ? $t('label.success')
                  : $t('message.failedTryAgain')
              "
              :label="`${$t('label.authorization')} status`"
              disabled
              outlined
              persistent-hint
              v-model="teamleaderMe.id"
            >
              <v-icon
                :color="teamleaderMe.id ? 'success' : 'error'"
                slot="prepend-inner"
              >
                {{ teamleaderMe.id ? "verified_user" : "security" }}
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-row class="fill-height" align-content="center" justify="center" v-else>
        <v-col class="text-center" cols="12">
          {{ $t("message.loginWithYourAccount") }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
        <v-col class="text-center" cols="12">
          {{ $t("message.installationResumes") }}
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="!isLoggingIn">
      <v-spacer />
      <v-btn
        @click="back()"
        :class="['black--text', 'mr-2']"
        rounded
        small
        text
      >
        {{ $t("label.back") }}
      </v-btn>
      <v-btn
        @click="reAuth()"
        :class="['black--text', 'mr-2']"
        rounded
        small
        text
      >
        {{ $t("label.retry") }}
      </v-btn>
      <v-btn
        :class="['white--text']"
        :color="'success'"
        :disabled="!isFormValid"
        @click="next()"
        rounded
        small
      >
        {{ $t("label.next") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "teamleader-install",
  props: {
    getAuthCode: {
      type: Boolean,
      default: false,
    },
    selectedCompanies: {
      type: Array,
      default: [],
    },
    step: {
      type: Number,
      default: 1,
    },
    token: {
      type: Object,
      default: {},
    },
  },
  components: {},

  apollo: {
    teamleaderToken: {
      query: gql`
        query teamleaderToken(
          $clientId: String!
          $redirectUri: String!
          $code: String!
        ) {
          teamleaderToken(
            clientId: $clientId
            redirectUri: $redirectUri
            code: $code
          ) {
            accessToken
            tokenType
            expiresIn
            refreshToken
            expiresAt
          }
        }
      `,
      variables() {
        return {
          clientId: this.clientId,
          redirectUri: this.redirectUri,
          code: this.$router.currentRoute.query.code,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.teamleaderToken,
      skip: true,
      result() {
        this.$apollo.queries.teamleaderMe.skip = false;

        this.$emit("update:token", this.teamleaderToken);
      },
    },

    teamleaderMe: {
      query: gql`
        query teamleaderMe($accessToken: String!) {
          teamleaderMe(accessToken: $accessToken) {
            id
            email
          }
        }
      `,
      variables() {
        return {
          accessToken: this.teamleaderToken.accessToken,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.teamleaderMe,
      skip: true,
    },
  },
  data: function () {
    return {
      clientId: "2a1e204cefa8b6ec3eb1f42c5972ddae",
      isFormValid: false,
      isLoggingIn: true,
      localSelectedCompanies: this.selectedCompanies,
      redirectUri: null,
      teamleaderToken: {},
      teamleaderMe: {},
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    getAuthCode(val) {
      if (val) {
        this.goToExternalLogin();
      }
    },

    localSelectedCompanies(val) {
      // Update the parent's selectedCompanies when the localSelectedCompanies changes
      this.$emit("update:selectedCompanies", val);
    },
  },
  created() {
    this.rules = rules;

    var baseUrl = window.location.origin;
    if (baseUrl == "http://localhost:8080") {
      this.redirectUri = `http://localhost:8000/${this.$route.params.externalId}`;
    } else {
      this.redirectUri = `${baseUrl}/${this.$route.params.externalId}`;
    }

    if (this.$router.currentRoute.query.code) {
      this.$apollo.queries.teamleaderToken.skip =
        !this.$apollo.queries.teamleaderToken.skip;

      this.isLoggingIn = false;

      this.next();
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$emit("update:step", this.step - 1);
    },

    goToExternalLogin() {
      window.location.href =
        "https://app.teamleader.eu/oauth2/authorize?client_id=" +
        this.clientId +
        "&response_type=code&redirect_uri=" +
        this.redirectUri;
    },

    next() {
      this.$emit("update:step", this.step + 1);
    },

    reAuth() {
      this.$router.replace({ query: null });
      this.back();
    },
  },
};
</script>
